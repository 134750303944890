import React from 'react';
import {
  Icon,
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleMasonryFlashList,
  TextInput,
  VStack,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Modal,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SupabaseApi from '../apis/SupabaseApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const SwiftHistoryPeopleScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [currentID, setCurrentID] = React.useState('');
  const [currentPeople, setCurrentPeople] = React.useState('');
  const [currentURL, setCurrentURL] = React.useState('');
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalWebVisible, setModalWebVisible] = React.useState(false);
  const [searchPeople, setSearchPeople] = React.useState('');
  const [tempSearch, setTempSearch] = React.useState('');
  const FilterList = list => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    console.log(list, tempSearch);
    const newList = list.filter(item =>
      item.name.toLowerCase().includes(tempSearch)
    );
    return newList;
  };

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
    >
      {/* People Fetch */}
      <SupabaseApi.FetchPeopleSearchdynamicGET peoplesearch={searchPeople}>
        {({ loading, error, data, refetchPeopleSearchdynamic }) => {
          const peopleFetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* Search */}
              <View
                style={StyleSheet.applyWidth(
                  { padding: '2%' },
                  dimensions.width
                )}
              >
                <View>
                  <View>
                    {/* Search Field */}
                    <TextInput
                      autoCapitalize={'none'}
                      changeTextDelay={500}
                      onChangeText={newSearchFieldValue => {
                        try {
                          setTempSearch(newSearchFieldValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)['Text Input']
                        .props}
                      autoComplete={'name'}
                      autoCorrect={false}
                      clearButtonMode={'always'}
                      editable={true}
                      placeholder={'Search...'}
                      placeholderTextColor={theme.colors.text.light}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextInputStyles(theme)['Text Input']
                            .style,
                          {
                            borderBottomWidth: null,
                            borderColor: theme.colors.text.medium,
                            borderLeftWidth: null,
                            borderRightWidth: null,
                            borderTopWidth: null,
                            borderWidth: 1,
                            fontSize: [
                              { minWidth: Breakpoints.Desktop, value: 22 },
                              { minWidth: Breakpoints.Mobile, value: 16 },
                            ],
                            padding: 8,
                            paddingBottom: null,
                            paddingLeft: null,
                            paddingRight: null,
                            paddingTop: null,
                          }
                        ),
                        dimensions.width
                      )}
                      value={tempSearch}
                    />
                  </View>
                </View>
              </View>
              {/* Masonry List - Small */}
              <>
                {dimensions.width >= Breakpoints.Tablet ? null : (
                  <SimpleStyleMasonryFlashList
                    data={FilterList(peopleFetchData)}
                    keyExtractor={(masonryListSmallData, index) =>
                      masonryListSmallData?.id
                    }
                    listKey={'6eZ1ADPu'}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const masonryListSmallData = item;
                      return (
                        <Pressable
                          onPress={() => {
                            console.log('Pressable ON_PRESS Start');
                            let error = null;
                            try {
                              console.log('Start ON_PRESS:0 FETCH_REQUEST');
                              /* hidden 'API Request' action */ console.log(
                                'Complete ON_PRESS:0 FETCH_REQUEST'
                              );
                              console.log('Start ON_PRESS:1 SET_VARIABLE');
                              /* hidden 'Set Variable' action */ console.log(
                                'Complete ON_PRESS:1 SET_VARIABLE'
                              );
                              console.log('Start ON_PRESS:2 SET_VARIABLE');
                              /* hidden 'Set Variable' action */ console.log(
                                'Complete ON_PRESS:2 SET_VARIABLE'
                              );
                              console.log('Start ON_PRESS:3 SET_VARIABLE');
                              /* hidden 'Set Variable' action */ console.log(
                                'Complete ON_PRESS:3 SET_VARIABLE'
                              );
                              console.log('Start ON_PRESS:4 SET_VARIABLE');
                              /* hidden 'Set Variable' action */ console.log(
                                'Complete ON_PRESS:4 SET_VARIABLE'
                              );
                              console.log('Start ON_PRESS:5 NAVIGATE');
                              navigation.navigate('StackNavigator', {
                                screen: 'SwiftHistoryInformationScreen',
                                params: {
                                  description:
                                    masonryListSmallData?.description,
                                  name: masonryListSmallData?.name,
                                  image: masonryListSmallData?.image,
                                  url: masonryListSmallData?.url,
                                },
                              });
                              console.log('Complete ON_PRESS:5 NAVIGATE');
                            } catch (err) {
                              console.error(err);
                              error = err.message ?? err;
                            }
                            console.log(
                              'Pressable ON_PRESS Complete',
                              error ? { error } : 'no error'
                            );
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignContent: 'center',
                                alignItems: 'center',
                                alignSelf: 'center',
                                borderRadius: 10,
                                borderWidth: 1,
                                flexWrap: 'wrap',
                                margin: 2,
                                padding: 5,
                                paddingLeft: 10,
                                width: '90%',
                              },
                              dimensions.width
                            )}
                          >
                            <>
                              {!masonryListSmallData?.image ? null : (
                                <Image
                                  {...GlobalStyles.ImageStyles(theme)['Image']
                                    .props}
                                  resizeMode={'center'}
                                  source={{
                                    uri: `${masonryListSmallData?.image}`,
                                  }}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ImageStyles(theme)['Image']
                                        .style,
                                      {
                                        minHeight: [
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 200,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 50,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 250,
                                          },
                                        ],
                                        minWidth: [
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 250,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 200,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 50,
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              accessibilityRole={'button'}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: palettes.Brand.Black,
                                    fontFamily: 'System',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 22,
                                      },
                                    ],
                                    fontWeight: '400',
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {masonryListSmallData?.name}
                            </Text>
                          </View>
                        </Pressable>
                      );
                    }}
                    showsVerticalScrollIndicator={true}
                    estimatedItemSize={76}
                    numColumns={2}
                    showsHorizontalScrollIndicator={false}
                  />
                )}
              </>
              {/* Masonry List - Large */}
              <>
                {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                  <SimpleStyleMasonryFlashList
                    data={FilterList(peopleFetchData)}
                    keyExtractor={(masonryListLargeData, index) =>
                      masonryListLargeData?.id
                    }
                    listKey={'upkhgGBb'}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const masonryListLargeData = item;
                      return (
                        <Pressable
                          onPress={() => {
                            console.log('Pressable ON_PRESS Start');
                            let error = null;
                            try {
                              console.log('Start ON_PRESS:0 FETCH_REQUEST');
                              /* hidden 'API Request' action */ console.log(
                                'Complete ON_PRESS:0 FETCH_REQUEST'
                              );
                              console.log('Start ON_PRESS:1 SET_VARIABLE');
                              /* hidden 'Set Variable' action */ console.log(
                                'Complete ON_PRESS:1 SET_VARIABLE'
                              );
                              console.log('Start ON_PRESS:2 SET_VARIABLE');
                              /* hidden 'Set Variable' action */ console.log(
                                'Complete ON_PRESS:2 SET_VARIABLE'
                              );
                              console.log('Start ON_PRESS:3 SET_VARIABLE');
                              /* hidden 'Set Variable' action */ console.log(
                                'Complete ON_PRESS:3 SET_VARIABLE'
                              );
                              console.log('Start ON_PRESS:4 SET_VARIABLE');
                              /* hidden 'Set Variable' action */ console.log(
                                'Complete ON_PRESS:4 SET_VARIABLE'
                              );
                              console.log('Start ON_PRESS:5 NAVIGATE');
                              navigation.navigate('StackNavigator', {
                                screen: 'SwiftHistoryInformationScreen',
                                params: {
                                  description:
                                    masonryListLargeData?.description,
                                  name: masonryListLargeData?.name,
                                  image: masonryListLargeData?.image,
                                  url: masonryListLargeData?.url,
                                },
                              });
                              console.log('Complete ON_PRESS:5 NAVIGATE');
                            } catch (err) {
                              console.error(err);
                              error = err.message ?? err;
                            }
                            console.log(
                              'Pressable ON_PRESS Complete',
                              error ? { error } : 'no error'
                            );
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignContent: 'center',
                                alignItems: 'center',
                                alignSelf: 'center',
                                borderRadius: 10,
                                borderWidth: 1,
                                flexWrap: 'wrap',
                                margin: 2,
                                padding: 5,
                                paddingLeft: 10,
                                width: '90%',
                              },
                              dimensions.width
                            )}
                          >
                            <>
                              {!masonryListLargeData?.image ? null : (
                                <Image
                                  {...GlobalStyles.ImageStyles(theme)['Image']
                                    .props}
                                  resizeMode={'center'}
                                  source={{
                                    uri: `${masonryListLargeData?.image}`,
                                  }}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ImageStyles(theme)['Image']
                                        .style,
                                      {
                                        minHeight: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 150,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 50,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 250,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 200,
                                          },
                                        ],
                                        minWidth: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 150,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 50,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 250,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 200,
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              accessibilityRole={'button'}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: palettes.Brand.Black,
                                    fontFamily: 'System',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 22,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                    ],
                                    fontWeight: '400',
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {masonryListLargeData?.name}
                            </Text>
                          </View>
                        </Pressable>
                      );
                    }}
                    showsVerticalScrollIndicator={true}
                    estimatedItemSize={76}
                    numColumns={3}
                    showsHorizontalScrollIndicator={false}
                  />
                )}
              </>
            </>
          );
        }}
      </SupabaseApi.FetchPeopleSearchdynamicGET>
      <Utils.CustomCodeErrorBoundary>
        <CustomCode.ChangeStackNavigation navigation={props.navigation} />
      </Utils.CustomCodeErrorBoundary>
    </ScreenContainer>
  );
};

export default withTheme(SwiftHistoryPeopleScreen);
