import React from 'react';
import {
  AspectRatio,
  Divider,
  Link,
  Pressable,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SupabaseApi from '../apis/SupabaseApi.js';
import * as CustomCode from '../custom-files/CustomCode';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  description: 'testDescription',
  image: '',
  name: 'testTitle',
  url: '',
};

const SwiftHistoryInformationScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          alignSelf: 'stretch',
          backgroundColor: [
            { minWidth: Breakpoints.Tablet, value: palettes.Brand.White },
            { minWidth: Breakpoints.Mobile, value: palettes.Brand.White },
          ],
          padding: '2%',
        },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          { alignItems: 'center', flex: 1 },
          dimensions.width
        )}
      >
        {/* Title */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: palettes.Brand.Black,
              fontFamily: 'System',
              fontSize: [
                { minWidth: Breakpoints.Mobile, value: 16 },
                { minWidth: Breakpoints.Desktop, value: 22 },
              ],
              fontWeight: '700',
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {props.route?.params?.name ?? defaultProps.name}
        </Text>
        <>
          {!(props.route?.params?.image ?? defaultProps.image) ? null : (
            <AspectRatio
              aspectRatio={1.5}
              style={StyleSheet.applyWidth(
                {
                  margin: 10,
                  width: [
                    { minWidth: Breakpoints.Mobile, value: '50%' },
                    { minWidth: Breakpoints.Laptop, value: '25%' },
                  ],
                },
                dimensions.width
              )}
            >
              <Pressable
                onPress={() => {
                  try {
                    navigation.navigate('StackNavigator', {
                      screen: 'SwiftHistoryBrowserScreen',
                      params: {
                        url: props.route?.params?.url ?? defaultProps.url,
                      },
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={{
                    uri: `${props.route?.params?.image ?? defaultProps.image}`,
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      { height: '100%', width: '100%' }
                    ),
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors.text.light,
                        fontFamily: 'System',
                        fontSize: { minWidth: Breakpoints.Desktop, value: 20 },
                        fontWeight: '400',
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Click image for gallery.'}
                </Text>
              </Pressable>
            </AspectRatio>
          )}
        </>
        {/* Description */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              fontSize: [
                { minWidth: Breakpoints.Mobile, value: 16 },
                { minWidth: Breakpoints.Desktop, value: 22 },
              ],
              marginTop: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Desktop, value: 25 },
              ],
            }),
            dimensions.width
          )}
        >
          {props.route?.params?.description ?? defaultProps.description}
        </Text>
        <Divider
          {...GlobalStyles.DividerStyles(theme)['Divider'].props}
          color={theme.colors.text.medium}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.DividerStyles(theme)['Divider'].style,
              { marginBottom: 10, marginTop: 10, width: '75%' }
            ),
            dimensions.width
          )}
        />
        <Link
          accessible={true}
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.LinkStyles(theme)['Link'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'].style, {
              color: theme.colors.text.light,
              fontFamily: 'System',
              fontSize: [
                { minWidth: Breakpoints.Mobile, value: 16 },
                { minWidth: Breakpoints.Desktop, value: 22 },
              ],
              fontStyle: 'italic',
              fontWeight: '400',
            }),
            dimensions.width
          )}
          title={'Return to List'}
        />
      </View>
      <Utils.CustomCodeErrorBoundary>
        <CustomCode.ChangeStackNavigation navigation={props.navigation} />
      </Utils.CustomCodeErrorBoundary>
    </ScreenContainer>
  );
};

export default withTheme(SwiftHistoryInformationScreen);
