import React from 'react';
import { ScreenContainer, WebView, withTheme } from '@draftbit/ui';
import { View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as CustomCode from '../custom-files/CustomCode';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { url: '' };

const SwiftHistoryBrowserScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.Brand.White,
          },
        },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          { height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        <WebView
          allowFileAccessFromFileURLs={false}
          allowUniversalAccessFromFileURLs={false}
          cacheEnabled={true}
          incognito={false}
          javaScriptCanOpenWindowsAutomatically={false}
          javaScriptEnabled={true}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={true}
          startInLoadingState={false}
          {...GlobalStyles.WebViewStyles(theme)['Web View'].props}
          mediaPlaybackRequiresUserAction={true}
          source={{ uri: `${props.route?.params?.url ?? defaultProps.url}` }}
          style={StyleSheet.applyWidth(
            GlobalStyles.WebViewStyles(theme)['Web View'].style,
            dimensions.width
          )}
        />
      </View>
      <Utils.CustomCodeErrorBoundary>
        <CustomCode.ChangeStackNavigation navigation={props.navigation} />
      </Utils.CustomCodeErrorBoundary>
    </ScreenContainer>
  );
};

export default withTheme(SwiftHistoryBrowserScreen);
