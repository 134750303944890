import React from 'react';
import { MapMarker, MapView } from '@draftbit/maps';
import { ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SupabaseApi from '../apis/SupabaseApi.js';
import * as CustomCode from '../custom-files/CustomCode';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const SwiftHistoryMapScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Tablet,
            value: palettes.Brand.White,
          },
        },
        dimensions.width
      )}
    >
      {/* Map View */}
      <View
        style={StyleSheet.applyWidth(
          { height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        {/* Map Fetch */}
        <SupabaseApi.FetchPlacesActiveTentativeGET refetchInterval={900000}>
          {({ loading, error, data, refetchPlacesActiveTentative }) => {
            const mapFetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <MapView
                apiKey={'AIzaSyBBI1JGONvwCoBIJw-FhyGCom_N_cledLg'}
                customMapStyle={'Lorem ipsum dolor sit amet'}
                keyExtractor={(mapViewData, index) =>
                  mapViewData?.id ??
                  mapViewData?.uuid ??
                  index?.toString() ??
                  JSON.stringify(mapViewData)
                }
                listKey={'mW5RrIbu'}
                loadingEnabled={true}
                markersData={mapFetchData}
                moveOnMarkerPress={true}
                renderItem={({ item, index }) => {
                  const mapViewData = item;
                  return (
                    <MapMarker
                      androidUseDefaultIconImplementation={false}
                      pinImageSize={50}
                      description={mapViewData?.description}
                      flat={true}
                      latitude={mapViewData?.['gps-lat']}
                      longitude={mapViewData?.['gps-lng']}
                      pinColor={theme.colors.background.danger}
                      pinImage={''}
                      title={mapViewData?.name}
                      tracksViewChanges={false}
                    />
                  );
                }}
                scrollEnabled={true}
                zoomEnabled={true}
                {...GlobalStyles.MapViewStyles(theme)['Map View'].props}
                autoClusterMarkers={true}
                autoClusterMarkersDistanceMeters={25}
                latitude={50.281921}
                longitude={-107.799888}
                mapType={'terrain'}
                provider={'google'}
                rotateEnabled={false}
                showsCompass={true}
                showsPointsOfInterest={false}
                showsUserLocation={false}
                style={StyleSheet.applyWidth(
                  GlobalStyles.MapViewStyles(theme)['Map View'].style,
                  dimensions.width
                )}
                zoom={13}
              />
            );
          }}
        </SupabaseApi.FetchPlacesActiveTentativeGET>
      </View>
      <Utils.CustomCodeErrorBoundary>
        <CustomCode.ChangeStackNavigation navigation={props.navigation} />
      </Utils.CustomCodeErrorBoundary>
    </ScreenContainer>
  );
};

export default withTheme(SwiftHistoryMapScreen);
